import { getDatabase, ref, get } from "firebase/database"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Navbar, Footer } from "../App"
import userLogoDiscord from "../../img/user-logo.webp"
import Loader from "../hook/Loader"

export function UserProfile() {
	const [data, setData] = useState([])
	const [errLoaded, setErrLoaded] = useState(false)
	const [dataTemplate, setDataTemplate] = useState([])
	const [loader, setLoader] = useState(true)

	let { userId } = useParams()
	const db = getDatabase()

	async function loadTemplateUser() {
		const userRef = ref(db, `User/${userId}`)

		try {
			const userData = await get(userRef)
			const dataUser = userData.val()

			if (dataUser === null) return
			setData(dataUser)
			let templateDataArray = []

			const dataUserTemplates = dataUser.templates === undefined ? [] : Object.values(dataUser.templates)

			const promises = dataUserTemplates.map(template => {
				const templateRef = ref(db, `Template/box/${template}`)
				return get(templateRef).then(snapshot => {
					templateDataArray.push(snapshot.val())
				})
			})

			await Promise.all(promises)

			setDataTemplate(templateDataArray)

			setTimeout(() => {
				setLoader(false)
			}, 300)
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		loadTemplateUser()
	}, [])

	return (
		<>
			<Loader loader={loader} />
			<Navbar />
			{errLoaded && (
				<div className="text-center w-full mt-72">
					<h2 className="text-5xl">Użytkownik nie istnieje</h2>
					<p className="my-2">System ten działa od 10.02.2024 i ta osoba nie jest zaliczona w bazie danych</p>
				</div>
			)}
			{!errLoaded && (
				<main className={`${loader ? "hidden" : "block"} show max-w-container mx-auto`}>
					<section>
						<div className="template-userProfile flex flex-col items-center mt-5">
							<div className="details w-7/12 max-xl:w-11/12 my-2">
								<h2 className="text-4xl font-semibold">Profil użytkownika</h2>
							</div>
							<div className="template-user-profile-main w-7/12 max-xl:w-11/12 max-md:flex-col bg-main-color-popup px-5 py-5 mb-10 rounded-lg">
								{data && (
									<div className="flex gap-2">
										<div className="">
											<img
												src={
													data.avatar === undefined || data.userId === undefined
														? userLogoDiscord
														: `https://cdn.discordapp.com/avatars/${data.userId}/${data.avatar}.webp`
												}
												alt="avatar uzytkownika"
												className="w-32 rounded-lg"
											/>
										</div>
										<div className="">
											<h2 className="text-3xl font-medium">
												{data.username} <span className="text-xl">({data.userId})</span>
											</h2>
											<p className="py-1 px-2 bg-orange-custom w-fit rounded-full mt-2 text-sm">
												{dataTemplate && dataTemplate.length} dodanych szablonów
											</p>
											<div className="">
												{data.role && data.role === "Administrator" ? (
													<p className="bg-red-700 w-fit mt-2 px-2 rounded-full">Administrator</p>
												) : (
													""
												)}
												{data.role && data.role === "Moderator" ? (
													<p className="bg-green-600 w-fit mt-2 px-2 rounded-full">Moderator</p>
												) : (
													""
												)}
												{data.role && data.role === "Support" ? (
													<div className="flex gap-1 bg-yellow-600 w-fit mt-2 px-2 rounded-full">
														<span className="material-symbols-outlined">support_agent</span>
														<p className="">Support</p>
													</div>
												) : (
													""
												)}
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</section>
					<section>
						<div className="templatelist flex flex-col items-center mb-32">
							<div className="details w-7/12 max-xl:w-11/12 my-2">
								<h2 className="text-4xl font-semibold">
									Szablony <span className="text-2xl">({dataTemplate && dataTemplate.length})</span>
								</h2>
								<p className={`${dataTemplate.length === 0 ? "block" : "hidden"}`}>
									Ten użytkownik nie ma dodanego żadnego <span className="font-bold">działającego</span> szablonu
								</p>
							</div>
							<div
								className={`w-7/12 max-xl:w-11/12 max-md:flex-col flex flex-wrap gap-5 ${
									dataTemplate === null ? "hidden" : "block"
								}`}>
								{Object.values(dataTemplate)
									.reverse()
									.map(template => (
										<div
											className={`card  bg-main-color-popup relative w-80 rounded-xl shadow-lg overflow-hidden mt-4 flex flex-col justify-between h-60 border-t-2 transition-colors border-orange-custom hover:border-[#10b6a8]`}>
											<div className="px-2 pt-3 flex-grow">
												<h2 className="text-2xl font-semibold text-orange-custom mb-2">{template && template.title}</h2>
												<p className="">{template && template.description}</p>
											</div>
											<div className="px-2 pb-2">
												<div className="text-sm text-gray-400">
													<p>Kategoria: {template && template.categories}</p>
													<p>Data opublikowania: {template && template.dateCreate}</p>
												</div>
												<div className="w-full mt-1">
													<a href={`/templates/${template && template.ID}`} target="_black" rel="noopener noreferrer">
														<button className="bg-orange-custom transition-colors hover:bg-[#167c74] text-white font-bold py-2 px-4 rounded w-full">
															POKAŻ SZABLON
														</button>
													</a>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</section>
				</main>
			)}
			<div className="absolute bottom-0 left-1/2 translate-x-[-50%]">
				<Footer />
			</div>
		</>
	)
}

import { useState } from "react"
import { Navbar, Footer } from "./App"
import SyntaxHighlighter from "react-syntax-highlighter"
import { darcula } from "react-syntax-highlighter/dist/esm/styles/hljs"

export function Documentation() {
	const [visibleCategories, setVisibleCategories] = useState(5)

	const jsonErr = `{ "message": "Przekroczono limit żądań, spróbuj ponownie później" }`
	const fuzzySearch = `https://szablonydiscord.pl/api/v2/search/templates`
	const apiTemplatesPoint = `https://szablonydiscord.pl/api/v2/templates`
	const apiTemplatesAddPoint = `https://szablonydiscord.pl/api/v2/templates/create`
	const apiTemplateReport = `https://szablonydiscord.pl/api/v2/templates/report`
	const callApiTemplate = `fetch("https://szablonydiscord.pl/api/v2/templates", {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"x-api-key": "API_KEY", //twój wygenerowany klucz API
		},
	}).then(res => res.json()).then(data => console.log(data))`
	const sendRequest = `fetch("https://szablonydiscord.pl/api/v2/templates/create", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"x-api-key": "API_KEY", //twój wygenerowany klucz API
		},
		body: JSON.stringify({ id, categories }),
	}).then(res => res.json()).then(data => console.log(data))`

	const fuzzySearchReq = `fetch("https://szablonydiscord.pl/api/v2/search/templates", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"x-api-key": "API_KEY", //twój wygenerowany klucz API
		},
		body: JSON.stringify({ name }),
	}).then(res => res.json()).then(data => console.log(data))`

	const sendRequestReport = `<form action='https://szablonydiscord.pl/api/v2/templates/report' method='post'>
	<input type='text' name='id' />
	<input type='text' name='reason' />
	<button type='submit'>wyslij</button>
</form>`

	const categoriesTemplate = [
		"Wszystkie",
		"Społecznościowe",
		"Roleplay",
		"Tematyczne",
		"Programistyczne",
		"Angielskie",
		"NSFW",
		"Grupy-ludzi",
		"Gildie",
		"Memy",
		"Scam",
		"Anime",
		"GTA",
		"Minecraft",
		"Gamingowe",
		"Reklamowe",
		"Zdobione",
	]

	return (
		<>
			<Navbar />
			<main className="container mx-auto show">
				<div className="w-full px-2">
					<div className="flex justify-center mt-12">
						<div className="w-64 max-md:hidden">
							<h2 className="text-2xl font-semibold text-left">Tematy</h2>
							<ul>
								<li className="my-2 text-gray-300 transition-colors hover:text-gray-100">
									<a href="#ratelimit">Ratelimit</a>
								</li>
								<li className="my-2 text-gray-300 transition-colors hover:text-gray-100">
									<a href="#api-key">Klucz API</a>
								</li>
								<li className="my-2 text-gray-300 transition-colors hover:text-gray-100">
									<a href="#templates">Dostępność szablonów</a>
								</li>
								<li className="my-2 text-gray-300 transition-colors hover:text-gray-100">
									<a href="#get-templates">Pobieranie szablonów</a>
								</li>
								<li className="my-2 text-gray-300 transition-colors hover:text-gray-100">
									<a href="#template-add">Dodawanie szablonów</a>
								</li>
								<li className="my-2 text-gray-300 transition-colors hover:text-gray-100">
									<a href="#fuzzy-search">Fuzzy searching</a>
								</li>
								<li className="my-2 text-gray-300 transition-colors hover:text-gray-100">
									<a href="#available-templates">Dozwolone kategorie</a>
								</li>
								<li className="my-2 text-gray-300 transition-colors hover:text-gray-100">
									<a href="#report-template">Zgłoszenia szablonu</a>
								</li>
								<li className="my-2 text-gray-300 transition-colors hover:text-gray-100">
									<a href="#status-website">Status systemów</a>
								</li>
								<li className="my-2 text-gray-300 transition-colors hover:text-gray-100">
									<a href="#codes-err">Kody błędów</a>
								</li>
							</ul>
						</div>
						<div className="flex flex-col w-1/2 max-2xl:w-2/3 max-md:w-11/12">
							<section className="about">
								<h2 className="text-2xl font-black">API Szablony</h2>
								<p className="mt-2 text-gray-200">
									API szablonowe stworzone z myślą o osobach które chciały by postawić swoją stronę lub bota o tematyce
									szablonów nie musząc dodawać wszystkich szablonow na nowo, za pomocą naszego API uzyskasz dostęp do
									największej bazy szablonów discord. Za pomocą naszego API równiez będziesz mógł dodać szablony. Każdy
									szablon przed dodaniem jest filtrowany, więc nie ma mowy o trollach!
								</p>
							</section>
							<section className="templates-add" id="status-website">
								<h2 className="text-2xl font-black mt-10">Status systemów</h2>
								<p className="mt-1">
									Status działania API oraz strony szablonydiscord.pl można sprawdzić na naszej stronie ze statusami.
									Dane odświeżają sie co 5 minut.{" "}
									<a href="https://status.shizeclone.eu" className="text-orange-custom underline">
										Sprawdź status
									</a>
								</p>
							</section>
							<section className="templates-add" id="api-key">
								<h2 className="text-2xl font-black mt-10">Klucz API</h2>
								<p className="mt-1">
									Aby móc korzystać z naszego API musisz wygenerować klucz API który jest dostępny w naszym panelu.
									Klucze API musza być przesyłane do serwera w nagłowku <span className="font-black">x-api-key</span>.{" "}
									<a href="/login" className="text-orange-custom underline">
										Zaloguj się
									</a>
								</p>
							</section>

							<section className="templates-add" id="templates">
								<h2 className="text-2xl font-black mt-10">Dostępność szablonów</h2>
								<p className="mt-1">
									Nad dostępnością oraz działaniem szablonów jest odpowiedzialny osobny, niezależny od całej aplikacji
									mikroserwis. Jeśli strona nawet nie będzie działać skanowanie szablonów będzie się odbywać. Z racji,
									że w bazie jest 3500+ szablonów skanowanie trwa nawet 3 godziny!
								</p>
							</section>

							<section className="ratelimit" id="ratelimit">
								<h2 className="text-2xl font-black mt-10">Ratelimit</h2>
								<p className="mt-2 text-gray-200">
									Nasze API stosuje ograniczenia liczby żądań w celu zapewnienia równego dostępu dla wszystkich
									użytkowników i ochrony przed nadmiernym obciążeniem. Każdy użytkownik może wykonać do 100 żądań na
									minutę. Jeden klucz API umożliwia max 1mln źądań.{" "}
								</p>
								<table className="mt-2 w-full">
									<thead>
										<tr>
											<th className="text-left">Nazwa nagłówka</th>
											<th className="text-left">Opis</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="py-2 w-3/12 text-sm">RateLimit-Limit</td>
											<td className="text-sm   whitespace-nowrap">Maksymalna liczba dozwolonych żądań.</td>
										</tr>
										<tr>
											<td className="py-2 w-3/12 text-sm">RateLimit-Remaining</td>
											<td className="text-sm  whitespace-nowrap">Liczba pozostałych dozwolonych żądań.</td>
										</tr>

										<tr>
											<td className="py-2 w-3/12 text-sm">RateLimit-Reset</td>
											<td className="text-sm  whitespace-nowrap">Czas resetu limitu żądań.</td>
										</tr>
									</tbody>
								</table>
								<h3 className="text-xl font-black mt-5">Przekroczenie limitu</h3>
								<p className="mt-1 text-gray-200">
									Przy przekroczeniu limitu żądań, odpowiedź będzie zawierać kod statusu `429 Too Many Requests` oraz
									komunikat:
								</p>
								<div className="mt-2">
									<SyntaxHighlighter language="json" style={darcula}>
										{jsonErr}
									</SyntaxHighlighter>
								</div>
							</section>

							<section className="get-templates">
								<h2 className="text-2xl font-black mt-10" id="get-templates">
									Pobieranie szablonów
								</h2>
								<div className="mt-1">
									<p>Metoda GET</p>
									<SyntaxHighlighter language="plaintext" style={darcula}>
										{apiTemplatesPoint}
									</SyntaxHighlighter>
								</div>

								<h3 className="text-xl font-black mt-5">Parametry</h3>
								<table>
									<tbody>
										<tr>
											<th className="py-2 text-left">id</th>
											<td className="px-2">Unikalne ID każdego szablonu</td>
										</tr>

										<tr>
											<th className="py-2 text-left">categories</th>
											<td className="px-2">Kategoria w jakim znajduje sie szablon</td>
										</tr>
										<tr>
											<th className="py-2 text-left">dateCreate</th>
											<td className="px-2">Data dodania szablonu do bazy danych (NIE JEST TO DATA STWORZENIA)</td>
										</tr>
										<tr>
											<th className="py-2 text-left">description</th>
											<td className="px-2">Opis szablonu</td>
										</tr>
										<tr>
											<th className="py-2 text-left">title</th>
											<td className="px-2">Tytuł szablonu</td>
										</tr>
										<tr>
											<th className="py-2 text-left">link</th>
											<td className="px-2">link do szablonu</td>
										</tr>
										<tr>
											<th className="py-2 text-left">clickButtonUse</th>
											<td className="px-2">Ilość osób ile kliknęło przycisk aby skorzystac z szablonu</td>
										</tr>
										<tr>
											<th className="py-2 text-left">usageCount</th>
											<td className="px-2">Ilość użyć szablonu (pobiera z api discorda co 24h)</td>
										</tr>
										<tr>
											<th className="py-2 text-left">server</th>
											<td className="px-2">
												Serwer do którego jest przydzielony szablon <span className="underline">opcjonalne</span>
											</td>
										</tr>
										<tr>
											<th className="py-2 text-left">serverLink</th>
											<td className="px-2">
												Link do serwera przydzielonego szablonu <span className="underline">opcjonalne</span>
											</td>
										</tr>
									</tbody>
								</table>
								<h3 className="text-xl font-black mt-5">Query parametr</h3>
								<table>
									<tbody>
										<tr>
											<th className="py-2 text-left">count</th>
											<td className="px-2">Liczba ile szablonów ma zwrócić API (dostępne 3500+)</td>
										</tr>
										<tr>
											<th className="py-2 text-left">moreTemplateInfo</th>
											<td className="px-2">
												Zwraca więcej informacji o szablonie (data dodania, ilosc uzyc, informacje o serwerze szablonu)
												Standardowo na <span className="font-bold">false</span>
											</td>
										</tr>
									</tbody>
								</table>

								<h3 className="text-xl font-black mt-5">Przykładowe wywołanie</h3>
								<div className="mt-2">
									<SyntaxHighlighter language="javascript" style={darcula}>
										{callApiTemplate}
									</SyntaxHighlighter>
								</div>
							</section>
							<section id="fuzzy-search">
								<h2 className="text-2xl font-black mt-10" id="get-templates">
									Fuzzy searching
								</h2>
								<div className="mt-1">
									<p>Metoda POST</p>
									<SyntaxHighlighter language="plaintext" style={darcula}>
										{fuzzySearch}
									</SyntaxHighlighter>
								</div>

								<h3 className="text-xl font-black mt-5">Parametry</h3>
								<table>
									<tbody>
										<tr>
											<th className="py-2 text-left">name</th>
											<td className="px-2">Nazwa wyszukiwanego szablonu</td>
										</tr>
										<tr>
											<th className="py-2 text-left">threshold</th>
											<td className="px-2">Określa po wyniku (0.00 - 1.00) jak dopasowywują sie szablony</td>
										</tr>
									</tbody>
								</table>
								<div className="">
									<h3 className="text-xl font-black mt-5">Informacja</h3>
									<ul>
										<li>
											im ustawimy wyższy threshold to będzie dopasowywało mniej precyzyjniej szablony (jak sie wpisze
											fraze to znajdzie szablon nie pasujący do tego).
										</li>
										<li className="my-2">
											<p>Im niższy ustawimy threshold tym precyzyjniej będzie dopasowywać.</p>
										</li>
										<li className="font-black underline ">ZALECANY THRESHOLD TO 0.3</li>
									</ul>
								</div>
								<h3 className="text-xl font-black mt-5">Przykładowe wywołanie</h3>
								<div className="mt-2">
									<SyntaxHighlighter language="javascript" style={darcula}>
										{fuzzySearchReq}
									</SyntaxHighlighter>
								</div>
							</section>

							<section className="templates-add" id="template-add">
								<h2 className="text-2xl font-black mt-10">Dodawanie szablonów</h2>
								<div className="mt-1">
									<p>Metoda POST</p>
									<SyntaxHighlighter language="plaintext" style={darcula}>
										{apiTemplatesAddPoint}
									</SyntaxHighlighter>
									<h3 className="text-xl font-black mt-5">Parametry</h3>
									<table>
										<tbody>
											<tr>
												<th className="py-2 text-left">id</th>
												<td className="px-2">
													ID szablonu discord, jesli mamy{" "}
													<span className="bg-codeblock-color px-1 py-1 rounded-lg">
														https://discord.new/dMndm8NvmPSz
													</span>{" "}
													to <span className="bg-codeblock-color px-1 py-1 rounded-lg">dMndm8NvmPSz</span> jest id
													szablonu
												</td>
											</tr>
											<tr>
												<th className="py-2 text-left">categories</th>
												<td className="px-2">Nazwa kategorii do której ma zostać dodany szablon</td>
											</tr>
										</tbody>
									</table>
									<h3 className="text-xl font-black mt-5">Przykładowe zapytanie</h3>
									<div className="mt-1">
										<SyntaxHighlighter language="javascript" style={darcula}>
											{sendRequest}
										</SyntaxHighlighter>
									</div>
									<h3 className="text-xl font-black mt-5" id="available-templates">
										Dozwolone nazwy kategorii
									</h3>
									<ul>
										{categoriesTemplate.slice(0, visibleCategories).map((category, index) => (
											<li key={index} className="py-1">
												{index}. {category}
											</li>
										))}
									</ul>
									<button
										className="text-xl transition-colors hover:text-orange-custom "
										onClick={() =>
											visibleCategories === 5
												? setVisibleCategories(prev => prev + categoriesTemplate.length)
												: setVisibleCategories(5)
										}>
										{visibleCategories === 5 ? "Pokaż więcej" : "Pokaż mniej"}
									</button>
								</div>
							</section>

							<section className="report-template" id="report-template">
								<h2 className="text-2xl font-black mt-10" id="get-templates">
									Zgłaszanie szablonów
								</h2>
								<div className="mt-1">
									<p>Metoda POST</p>
									<SyntaxHighlighter language="plaintext" style={darcula}>
										{apiTemplateReport}
									</SyntaxHighlighter>
								</div>
								<h3 className="text-xl font-black mt-5">Parametry</h3>
								<table>
									<tbody>
										<tr>
											<th className="py-2 text-left">id</th>
											<td className="px-2">ID szablonu który chcemy zgłosić</td>
										</tr>
										<tr>
											<th className="py-2 text-left">reason</th>
											<td className="px-2">Powód zgłoszenia (max 60 znaków)</td>
										</tr>
									</tbody>
								</table>
								<h3 className="text-xl font-black mt-5">Przykładowe zapytanie</h3>
								<div className="mt-1">
									<SyntaxHighlighter language="html" style={darcula}>
										{sendRequestReport}
									</SyntaxHighlighter>
								</div>
							</section>

							<section className="codes-err" id="codes-err">
								<h2 className="text-2xl font-black mt-10">Kody błędów</h2>
								<table>
									<tbody>
										<tr>
											<th className="py-2 text-left">1001</th>
											<td className="px-2">Szablon nie działa</td>
										</tr>
										<tr>
											<th className="py-2 text-left">1015</th>
											<td className="px-2">Nazwa kategorii jest nieprawidłowa</td>
										</tr>
										<tr>
											<th className="py-2 text-left">1030</th>
											<td className="px-2">Szablon istnieje już w bazie danych</td>
										</tr>
										<tr>
											<th className="py-2 text-left">1000</th>
											<td className="px-2">Szablon został dodany do bazy danych</td>
										</tr>
										<tr>
											<th className="py-2 text-left">1050</th>
											<td className="px-2">Szablon został zastrzeżony</td>
										</tr>
										<tr>
											<th className="py-2 text-left">2001</th>
											<td className="px-2">Błąd serwera</td>
										</tr>
									</tbody>
								</table>
							</section>
						</div>
					</div>
				</div>
			</main>
			<Footer />
		</>
	)
}
